<template>
  <div class="timeStep">
    <div class="stepItem" v-for="item in stepData" :key="item.time">
      <div class="time">
        <div class="dataTime">{{$formatTime(item.time)}}</div>
        <div class="data">{{$getDate(item.time)}}</div>
      </div>
      <div class="step">
        <div class="status">
          <span :style="{ color: getStatusData(item.status).color }"
        >{{ getStatusData(item.status).name }}</span>
        </div>
        <div class="text">维修师傅 {{item.user}}</div>
        <div class="text">{{ item.remark }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import shareDeviceOrderStatus from '@components/shareDeviceOrderStatus/shareDeviceOrderStatus'
export default {
  mixins:[shareDeviceOrderStatus],
  props:{
    stepData:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.timeStep {
  background: #ffffff;
  padding: 18px 8px;
  .stepItem {
    display: flex;
    .time {
      width: 94px;
      .dataTime {
        font-size: 16px;
        color: $color1;
        font-weight: bold;
      }
      .data {
        font-size: 12px;
        color: $color2;
      }
    }
    .step {
      flex: 1;
      padding-left: 20px;
      border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
      position: relative;
      min-height: 64px;
      &::before {
        content: "";
        background: #3e73fb;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: -6.5px;
      }

      .status {
        font-size: 14px;
        color: #000000;
      }
      .text {
        font-size: 12px;
        color: $color1;
      }
    }
    &:last-child .step {
      border-left: 0 none;
    }
  }
}
</style>