<template>
  <div class="baseInfo">
    <div class="header">
      <div class="spaceBetween">
        <span class="left">{{ report.deviceName }}</span>
        <span
          class="right"
          :style="{ background: report.inWarranty == 1 ? '' : 'red' }"
          >{{ report.inWarranty == 1 ? "在保" : "未在保" }}</span
        >
      </div>
      <div>{{ report.categoryName }}</div>
      <div>{{ report.address }}</div>
    </div>
    <div class="mian">
      <div class="title" style="font-size: 16px">基本信息</div>
      <div class="item">
        <span class="itemLabel">工单编号</span
        ><span class="itemValue"> {{ report.serialNumber }} </span>
      </div>
      <div class="item">
        <span class="itemLabel">设备类型</span
        ><span class="itemValue"> {{ report.categoryName }} </span>
      </div>
      <div class="item">
        <span class="itemLabel">故障等级</span
        ><span class="itemValue"
          ><van-rate
            v-model="report.severity"
            :size="14"
            color="#FFE51A"
            void-icon="star"
            void-color="#D5D5D5"
            readonly
        /></span>
      </div>
      <div class="item">
        <span class="itemLabel">联系电话</span
        ><span class="itemValue"> {{ report.phone }} </span>
      </div>
      <div class="item">
        <span class="itemLabel">报修人</span
        ><span class="itemValue"> {{ report.reportUser }} </span>
      </div>
      <div class="item">
        <span class="itemLabel">报障时间</span
        ><span class="itemValue"> {{ $getDateTime(report.reportTime) }} </span>
      </div>
      <div class="item">
        <span class="itemLabel">维修时间</span
        ><span class="itemValue">
          {{ report.repairTime ? $getDateTime(report.repairTime) : "-" }}
        </span>
      </div>
    </div>
    <div class="mian" v-show="showStatus">
      <div class="item">
        <span class="itemLabel">设备状态</span
        ><span class="itemValue">
          {{ getDeviceStatusTitle(report.deviceStatus) }}</span
        >
      </div>
      <div class="item">
        <span class="itemLabel">紧急度</span
        ><span class="itemValue">
          {{ getSeverityStatusTitle(report.severity) }}
        </span>
      </div>
    </div>
    <div class="mian">
      <div class="title">故障描述</div>
      <div class="textarea">故障现象：{{ report.detail }}</div>
      <div class="imageList" v-if="report.photos1 && report.photos1.length > 0">
        <div
          class="imageItem"
          v-for="(item, index) in report.photos1"
          :key="item.id"
        >
          <img
            :src="$replacePhoto(item.url)"
            class="userImg"
            @click="showBigImage(report.photos1, index)"
          />
        </div>
      </div>
      <div class="noPhoto" v-else>暂无故障图片</div>
    </div>
    <van-image-preview v-model="showImage" :images="images" :startPosition="imagesIdx" :closeable="true">
    </van-image-preview>
  </div>
</template>
<script>
import severityOption from "@/mixins/severityOption/index.js";
import deviceStatusOption from "@/mixins/deviceStatusOption/index.js";
import { ImagePreview } from "vant";
export default {
  name: "baseInfo",
  mixins: [severityOption, deviceStatusOption],
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showImage:false,
      images:[],
      imagesIdx:0
    };
  },
  created() {},
  methods: {
    showBigImage(photo, index) {
      this.images = [];
      this.imagesIdx = index
      for (let i = 0; i < photo.length; i++) {
        let data = photo[i];
        this.images.push(this.$replacePhoto(data.url));
      }
      this.showImage = true
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.header {
  background: #ffffff;
  padding: 0 12px;
  border-radius: 2px;
  font-size: 12px;
  color: $color3;
  height: 76px;
  margin-bottom: 10px;
  .spaceBetween {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding-top: 6px;
    .left {
      color: $color1;
    }
    .right {
      display: inline-block;
      width: 56px;
      height: 24px;
      line-height: 24px;
      background: #00e78c;
      border-radius: 4px;
      text-align: center;
      color: #ffffff;
    }
  }
}
.mian {
  background: $background_color2;
  padding: 12px;
  border-radius: 2px;
  margin-bottom: 10px;
  .item {
    font-size: 14px;
    height: 38px;
    line-height: 37px;
    .itemLabel {
      color: $color3;
    }
    .itemValue {
      float: right;
      .inputView {
        width: 132px;
        float: left;
        font-size: 14px;
      }
      .scanning {
        color: #3e73fb;
        img {
          width: 16px;
          vertical-align: sub;
        }
      }
    }
  }
  .title {
    font-size: 14px;
    color: $color3;
    padding-bottom: 6px;
    color: $color1;
    font-weight: 500;
  }
  .textarea {
    font-size: 12px;
    color: $color3;
  }
  .imageList {
    display: flex;
    overflow: scroll;
    margin-top: 10px;
    .imageItem {
      margin-right: 10px;
      position: relative;
      .userImg {
        width: 104px;
        height: 104px;
        border-radius: 2px;
      }
      .viewerClose {
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        line-height: 24px;
        text-align: center;
        right: 4px;
        top: 4px;
        background: rgba($color: #323233, $alpha: 0.2);
        color: #ffffff;
      }
    }

    .upload {
      .addImage {
        width: 104px;
        height: 104px;
      }
    }
  }
  .noPhoto {
    padding: 36px 0;
    text-align: center;
    color: $color3;
  }
}
::v-deep .van-image {
  scale: 0.8;
}
</style>