<template>
  <div class="repairInfo">
    <overflowYHidden>
      <baseInfo :report="report" :showStatus="true"></baseInfo>
      <timeStep :stepData="stepData"></timeStep>
      <div class="footer" @click="submit">确定</div>
    </overflowYHidden>
  </div>
</template>

<script>
import baseInfo from "./baseInfo.vue";
import timeStep from "./timeStep.vue";
import overflowYHidden from "@/components/overflowYHidden";
export default {
  components: { baseInfo, overflowYHidden, timeStep },
  data() {
    return {
      id: "",
      stepData: [],
      report: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.$nextTick(() => {
      this.reportDataInfo();
    });
  },
  methods: {
    reportDataInfo() {
      this.$showLoading({
        target: ".repairInfo",
      });
      this.$api.deviceRepair
        .reportDataInfo({
          id: this.id,
          photo:'1'
        })
        .then((res) => {
          this.$hideLoading({
            target: ".repairInfo",
          });
          this.stepData = res.data.data;
          this.report = res.data.report;
        })
        .catch((res) => {
          this.$hideLoading({
            target: ".repairInfo",
          });
        });
    },
    submit() {
      this.$back();
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.repairInfo {
  height: 100%;
  overflow: hidden;
  padding: 10px;
  .footer {
    margin: 18px 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #3e73fb;
    border-radius: 18px;
    color: #ffffff;
    font-size: 16px;
  }
}
</style>